import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { collection, doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userId: string;
  constructor(public alertController: AlertController, public router: Router, private auth: Auth, private firestore: Firestore, public loadingService: LoadingService) {}

  getUser(): Observable<User> {
    return authState(this.auth);
  }

  getUserAgain()  { 
    const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    return user 
  } else {
    return null
  }
}); 
     
  }

  async login(email: string, password: string): Promise<UserCredential> {
    this.loadingService.present('please wait while we log you in ...')
    try {
      
      // const newUserCredential: UserCredential = await createUserWithEmailAndPassword(this.auth, email, password)
      const logedinUserCredential = await  signInWithEmailAndPassword(this.auth, email, password)
    
      // console.log(logedinUserCredential, 'this is useful');
      
   this.loadingService.dismiss()
      return logedinUserCredential
    } catch (error) {
      this.loadingService.dismiss()
      if (error.code === "auth/network-request-failed"){
        // console.log("Network not found!")
        this.presentAlert("Error", "", "Failed, Network Error") 
        this.router.navigateByUrl('/login')}
  
        else if (error.code === "auth/wrong-password"){
          this.presentAlert("Error",  "", "Incorrect password")
          
          this.router.navigateByUrl('/login')}
  
        else if (error.code === "auth/user-not-found"){
        this.presentAlert("Error", "",  "User Not found")
        
        this.router.navigateByUrl('/login')}
  
        else if (error.code === "auth/invalid-email"){
          this.presentAlert("Error", "",  "Invalid email")
          
          this.router.navigateByUrl('/login')}
  
        else if (error.code === "auth/operation-not-allowed"){
            this.presentAlert("Error", "",  "User Disabled")
            
            this.router.navigateByUrl('/login')
          }
      
    }


  }

  async signup(email: string, password: string, name:string, phone:string, organisation:string, position:string): Promise<User> {
    this.loadingService.present('signing you up,  please hold ...')
    try {
      const newUserCredential: UserCredential = await createUserWithEmailAndPassword(this.auth, email, password)
      // console.log(newUserCredential);
      
      const userReference = doc(this.firestore, 'users', newUserCredential.user.uid);
      await setDoc(userReference, 
        { 
        userId: newUserCredential.user.uid,
        email,
        organisation,
        phone,
        position,
        name,
        deviceToken: '',
        picture: 'assets/imgs/portrait.png', 
        role: '',
        state: '',
        country: ''

   } ).then(async () => { 
    
   })
      return newUserCredential.user;
    } catch (error) {
      this.loadingService.dismiss()
      if (error.code === "auth/network-request-failed"){
        // console.log("Network not found!")
        this.presentAlert("Error", "", "Failed, Network Error") 
        this.router.navigateByUrl('/register')}
  
        else if (error.code === "auth/wrong-password"){
          this.presentAlert("Error",  "", "Incorrect password")
          
          this.router.navigateByUrl('/register')}
  
        else if (error.code === "auth/user-not-found"){
        this.presentAlert("Error", "",  "User Not found")
        
        this.router.navigateByUrl('/register')}
  
        else if (error.code === "auth/invalid-email"){
          this.presentAlert("Error", "",  "Invalid email")
          
          this.router.navigateByUrl('/register')}
  
        else if (error.code === "auth/operation-not-allowed"){
            this.presentAlert("Error", "",  "User Disabled")
            
            this.router.navigateByUrl('/register')
          }
      
    }
  }

  resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  logout(): Promise<void> {
    return signOut(this.auth).then(() => {
      this.router.navigate(['/login'])
    })
  }

  async presentAlert(head, subhead, msg) {
    const alert = await this.alertController.create({
      header: head,
      subHeader: subhead,
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }
}
